.nav {
  align-items: center;

  &__list {
    align-items: center;
    gap: 51px;

    @include media(899px) {
      flex-direction: column;
      justify-content: center;
      gap: 25px;
      background-color: #fff;
      width: 100%;
      max-width: 400px;
    }
  }

  &__link {
    font-family: $main-font;
    font-size: $main-size;
    color: $dark-color;
    transition: color 0.3s ease-in-out;

    &:focus {
      outline: none;
      color: $active-color;
    }

    &:hover {
      color: $accent-color;
    }

    &:active {
      color: $active-color;
    }

    @include media(899px) {
      text-transform: uppercase;
      color: $accent-color;
    }
  }

  .nav__link--lang img {
    width: 48px;
    height: 48px;
  }

  @include media(899px) {
    display: flex;
    position: fixed;
    justify-content: flex-end;
    align-items: stretch;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    transform: translateX(110%);
    transition: 0.3s;
  }
}

.nav.nav--active {
  transform: translateX(0);
}
