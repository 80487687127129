.backtop-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  right: 50px;
  bottom: 20px;

  width: 50px;
  height: 50px;

  z-index: 100;

  font-size: 40px;

  background-color: $accent-color;
  border-radius: 50%;
  box-shadow: 0px 0px 20px 10px #ffffff33;

  @include media(767px) {
    right: 20px;
    width: 30px;
    height: 30px;
    font-size: 20px;
    bottom: 46px;
  }
}
