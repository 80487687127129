@mixin media($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;

  min-height: 100vh;
  width: 100%;

  font-family: $main-font;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  background-color: $site-bg-color;
}

main {
  flex-grow: 1;

  & > section + section {
    padding-top: 70px;
  }

  & > section:last-child {
    padding-bottom: 130px;

    @include media(899px) {
      padding-bottom: 10px;
    }
  }
}

a,
a:hover {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

input,
button,
button:focus,
input:focus {
  outline: none;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
}

input[type="submit"] {
  padding: 0;
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  width: 1170px;
  @include media(1199px) {
    width: 900px;
  }
  @include media(899px) {
    width: 100%;
  }
  @include media(767px) {
    width: 320px;
  }
}

.flex {
  display: flex;
}

.lock {
  overflow: hidden;
}

.svg-up,
.svg-lng {
  width: 24px;
  height: 24px;
}
