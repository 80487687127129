.map {
  width: 540px;
  @include media(899px) {
    width: 100%;
    height: 300px;
  }

  @include media(767px) {
    height: auto;
  }
}
