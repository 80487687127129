.card {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 260px;
  &__img {
    width: 200px;
    height: 200px;
    background-color: $accent-color;
    border-radius: 50%;
    margin-bottom: 40px;
  }
  &__title {
    position: relative;
    min-height: 72px;
    font-size: $card-text-size;
    &:after {
      content: "";
      position: absolute;
      width: 50px;
      height: 2px;
      background-color: $dark-color;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__text {
    padding: 0 20px;
  }
}
