*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  background-color: #f8f6f6;
}

main {
  flex-grow: 1;
}
main > section + section {
  padding-top: 70px;
}
main > section:last-child {
  padding-bottom: 130px;
}
@media screen and (max-width: 899px) {
  main > section:last-child {
    padding-bottom: 10px;
  }
}

a,
a:hover {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

input,
button,
button:focus,
input:focus {
  outline: none;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
}

input[type=submit] {
  padding: 0;
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  width: 1170px;
}
@media screen and (max-width: 1199px) {
  .container {
    width: 900px;
  }
}
@media screen and (max-width: 899px) {
  .container {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .container {
    width: 320px;
  }
}

.flex {
  display: flex;
}

.lock {
  overflow: hidden;
}

.svg-up,
.svg-lng {
  width: 24px;
  height: 24px;
}

.header {
  padding: 30px 0;
}
.header__container {
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .header__container {
    width: 100%;
  }
}

.header__img img {
  width: 100%;
}

.logo {
  cursor: pointer;
  width: 125px;
}
@media screen and (max-width: 899px) {
  .logo img {
    width: 90px;
  }
}

#header.fixed {
  padding: 5px 0;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  background-color: #008fd5;
  transition: background-color 0.3s ease-in-out;
}
@media screen and (max-width: 899px) {
  #header.fixed {
    display: none;
  }
}

#header.fixed .nav__link {
  color: #fff;
}
#header.fixed .nav__link:focus {
  outline: none;
  color: #e00e00;
}
#header.fixed .nav__link:hover {
  color: #003331;
}
#header.fixed .nav__link:active {
  color: #e00e00;
}

.about {
  padding-top: 70px;
}
.about__descr {
  color: #003331;
  font-size: 18px;
  line-height: 1.6;
  letter-spacing: 0.25px;
}
@media screen and (max-width: 767px) {
  .about__descr {
    font-size: 14px;
  }
}
.about__descr p + p {
  margin-top: 1em;
}
@media screen and (max-width: 767px) {
  .about {
    padding-top: 110px;
    margin-top: -90px;
  }
}

.footer {
  padding: 40px 0;
  background-color: #008fd5;
}
.footer__social {
  margin-bottom: 10px;
}

.products__carousel {
  padding-top: 30px;
}
@media screen and (max-width: 767px) {
  .products {
    padding-top: 90px;
    margin-top: -20px;
  }
}

.services__list {
  gap: 50px;
  justify-content: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .services__list {
    gap: 20px;
  }
}
.services__item .service-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .services {
    padding-top: 90px;
    margin-top: -50px;
  }
}

.contacts__content {
  padding-top: 40px;
}
@media screen and (max-width: 899px) {
  .contacts__content {
    flex-direction: column;
    gap: 40px;
  }
}
@media screen and (max-width: 767px) {
  .contacts__content {
    padding-top: 10px;
  }
}
@media screen and (max-width: 767px) {
  .contacts {
    padding-top: 90px;
    margin-top: -50px;
  }
}

.nav {
  align-items: center;
}
.nav__list {
  align-items: center;
  gap: 51px;
}
@media screen and (max-width: 899px) {
  .nav__list {
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    background-color: #fff;
    width: 100%;
    max-width: 400px;
  }
}
.nav__link {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #003331;
  transition: color 0.3s ease-in-out;
}
.nav__link:focus {
  outline: none;
  color: #e00e00;
}
.nav__link:hover {
  color: #008fd5;
}
.nav__link:active {
  color: #e00e00;
}
@media screen and (max-width: 899px) {
  .nav__link {
    text-transform: uppercase;
    color: #008fd5;
  }
}
.nav .nav__link--lang img {
  width: 48px;
  height: 48px;
}
@media screen and (max-width: 899px) {
  .nav {
    display: flex;
    position: fixed;
    justify-content: flex-end;
    align-items: stretch;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    transform: translateX(110%);
    transition: 0.3s;
  }
}

.nav.nav--active {
  transform: translateX(0);
}

.title-wrapper {
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .title-wrapper {
    margin-bottom: 20px;
  }
}

.section-title {
  position: relative;
  text-align: center;
  font-family: "Playfair Display", serif;
  font-size: 46px;
  font-weight: 400;
}
.section-title::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -25px;
  transform: translateX(-50%);
  width: 80px;
  height: 2px;
  background-color: #5186e2;
}
@media screen and (max-width: 767px) {
  .section-title::after {
    bottom: -10px;
  }
}
@media screen and (max-width: 767px) {
  .section-title {
    font-size: 36px;
  }
}

.card {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 260px;
}
.card__img {
  width: 200px;
  height: 200px;
  background-color: #008fd5;
  border-radius: 50%;
  margin-bottom: 40px;
}
.card__title {
  position: relative;
  min-height: 72px;
  font-size: 20px;
}
.card__title:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  background-color: #003331;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}
.card__text {
  padding: 0 20px;
}

.service-card {
  width: 290px;
}
.service-card__img {
  width: 200px;
  height: 200px;
}
@media screen and (max-width: 899px) {
  .service-card__img {
    width: 150px;
    height: 150px;
  }
}
@media screen and (max-width: 767px) {
  .service-card__img {
    width: 120px;
    height: 120px;
  }
}
.service-card__title {
  font-family: "Playfair Display", serif;
  position: relative;
  margin-bottom: 20px;
  min-height: 56px;
  font-size: 20px;
  text-transform: uppercase;
  white-space: nowrap;
}
.service-card__title::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  background-color: #003331;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
}
@media screen and (max-width: 767px) {
  .service-card__title::after {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .service-card__title {
    min-height: unset;
    font-size: 16px;
  }
}
.service-card__text {
  width: 270px;
}
@media screen and (max-width: 767px) {
  .service-card__text {
    text-align: center;
  }
}

.subtitle {
  font-family: "Playfair Display", serif;
  font-size: 28px;
  font-weight: 700;
}
.subtitle--mb {
  margin-bottom: 15px;
}

.address {
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}
.address > .subtitle {
  text-align: center;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .address > .subtitle {
    display: none;
  }
}
.address__list > li {
  display: flex;
}
.address__item {
  position: relative;
  align-items: center;
  gap: 15px;
}
.address__item + .address__item {
  margin-top: 1em;
}
.address__link {
  padding-left: 35px;
  font-size: 20px;
  color: #003331;
}
.address__link:focus {
  outline: none;
  color: #e00e00;
}
.address__link:hover {
  color: #008fd5;
}
.address__link:active {
  color: #e00e00;
}
@media screen and (max-width: 767px) {
  .address__link {
    font-size: 16px;
  }
}
.address__link--phone::before, .address__link--map::before, .address__link--mail::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  left: 0;
  top: 3px;
}
.address__link--phone::before {
  background-image: url("../img/contacts/phone.svg");
}
.address__link--map::before {
  background-image: url("../img/contacts/map.svg");
}
.address__link--mail::before {
  background-image: url("../img/contacts/mail.svg");
}

.map {
  width: 540px;
}
@media screen and (max-width: 899px) {
  .map {
    width: 100%;
    height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .map {
    height: auto;
  }
}

.social__list {
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.social__link {
  display: block;
}
.social__link:focus {
  outline: none;
  opacity: 0.8;
}
.social__link:hover {
  opacity: 0.8;
}
.social__link:active {
  opacity: 0.8;
}
.social__link img {
  width: 42px;
  height: 42px;
}

.nav-toggle {
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  z-index: 20;
}
.nav-toggle > .menu-bar {
  position: relative;
  width: 100%;
  height: 2px;
  background-color: #008fd5;
  transition: 0.1s;
}
.nav-toggle > .menu-bar::before, .nav-toggle > .menu-bar::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #008fd5;
  left: 0;
  transition: 0.3s;
}
.nav-toggle > .menu-bar::before {
  top: -10px;
}
@media screen and (max-width: 767px) {
  .nav-toggle > .menu-bar::before {
    top: -7px;
  }
}
.nav-toggle > .menu-bar::after {
  bottom: -10px;
}
@media screen and (max-width: 767px) {
  .nav-toggle > .menu-bar::after {
    bottom: -7px;
  }
}
@media screen and (max-width: 899px) {
  .nav-toggle {
    display: flex;
  }
}

.nav-toggle.nav-toggle--active > .menu-bar {
  background-color: transparent;
}
.nav-toggle.nav-toggle--active > .menu-bar::before {
  top: 0;
  transform: rotate(45deg);
}
.nav-toggle.nav-toggle--active > .menu-bar::after {
  top: 0;
  transform: rotate(-45deg);
}

.app__row {
  justify-content: center;
  gap: 50px;
}
@media screen and (max-width: 767px) {
  .app__row {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
.app__link {
  display: inline-block;
}
.app__link:focus {
  outline: none;
  opacity: 0.8;
}
.app__link:hover {
  opacity: 0.8;
}
.app__link:active {
  opacity: 0.8;
}

.backtop-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 50px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  z-index: 100;
  font-size: 40px;
  background-color: #008fd5;
  border-radius: 50%;
  box-shadow: 0px 0px 20px 10px rgba(255, 255, 255, 0.2);
}
@media screen and (max-width: 767px) {
  .backtop-btn {
    right: 20px;
    width: 30px;
    height: 30px;
    font-size: 20px;
    bottom: 46px;
  }
}