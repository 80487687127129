.app {
  &__row {
    justify-content: center;
    gap: 50px;

    @include media(767px) {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }

  &__link {
    display: inline-block;

    &:focus {
      outline: none;
      opacity: 0.8;
    }

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.8;
    }
  }
}
